// This component allows the user to edit a Panner object. An example of the Panner object is in the ExamplePanner.json file.
//

import React, { useState, useRef } from 'react';
import { Box, Text, Input, Button } from "@chakra-ui/react";

const PannerDetails = ({ panner, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const newNameRef = useRef(null);
  const newPremiumRef = useRef(null);

  const handleSaveClick = () => {
    const updatedName = newNameRef.current.value;
    const updatedPremium = newPremiumRef.current.value;
    // Send the updated values to the parent component (e.g., onEdit function)
    onEdit(panner.id, updatedName, updatedPremium);
    setIsEditing(false);
  };

  return (
    <Box>

        <Box >
          <Input defaultValue={panner.name} ref={newNameRef} fontWeight="bold"/>
          <Text width={600} fontSize={'sm'}>{panner.id}</Text>
          <br/>
          <p>Premium</p>
          <Input defaultValue={panner.premium} ref={newPremiumRef} />
          <br/><br/>
          <Button onClick={handleSaveClick} colorScheme="blue">Save</Button>
        </Box>

        
        {/* // This Box allows the user to edit placingFactrors in the Panner object. An example of the Panner object is in the ExamplePanner.json file. */}
        <Box>
          <Text fontWeight="bold" p={4}>
            Placing Factors
          </Text>
          <Box ml={4}>
            {/* This Box shows the details of each placingFactor */}

          </Box>
          <Text>End</Text>
        </Box>


    </Box>
  );
};

export default PannerDetails;
