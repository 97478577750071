import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Navbar from './Navbar';

const Codex = () => {


  return (
    <div>
      <Navbar />
      <h2>Codex</h2>
    </div>
  );
}

export default Codex;
