import React, { createContext, useState, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);

  const login = (token, username) => {
    setToken(token);
    setUsername(username);
  };

  const logout = () => {
    setToken(null);
    setUsername(null);
  };

  const contextValue = {
    token,
    username,
    login,
    logout,

    // New LinkedIn-related functions
    setLinkedInAccessToken: (accessToken) => setToken(accessToken),
    getLinkedInAccessToken: () => token,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Define the useAuth function
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
