// LinkedInCallback.js
import { useAuth } from "./AuthContext";
import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LinkedInCallback = () => {
  
    const navigate = useNavigate();
    const { setLinkedInAccessToken } = useAuth();

  useEffect(() => {
    // Parse the URL parameters to get the code
    const code = new URLSearchParams(window.location.search).get("code");

    console.log("Code:", code);
    // Make a request to your API to exchange the code for an access token
    axios
      .post("https://login.weirddata.com/Authentication/linkedin/exchange", { code })
      .then((response) => {
        // Handle the response, which contains the access token
        console.log("Response:", response);
        const accessToken = response.data.accessToken;
        console.log("Received access token:", accessToken);
        setLinkedInAccessToken(accessToken);

        // Redirect to the original page (you may need to modify the logic to match your routing setup)
        navigate('/');
      })
      .catch((error) => {
        // Handle errors
        console.error("Error during LinkedIn authentication:", error);
      });
  }, []);

  return <div>LinkedIn authentication in progress...</div>;
};

export default LinkedInCallback;
