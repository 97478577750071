import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from './AuthContext';
import PannerImage from "./images/Panner1.png";
import useGet from './useGet';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { VStack, Box, Flex, Text, Button, ButtonGroup, IconButton, Link, Grid, GridItem, Image } from "@chakra-ui/react";
import PannerProjects from './PannerProjects';
import PannerDetails from './PannerDetails'; 

const Panners = () => {
  const { token } = useContext(AuthContext); // Step 3
  const [projects, setProjects] = useState([]);
  const [panners, setPanners] = useState([]);
  const { data: projectsData, isPending, error } = useGet('https://panner.weirddata.com/api/projects', token);
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();
  const [selectedPanner, setSelectedPanner] = useState(null);
  const [fullPanner, setFullPanner] = useState(null);

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData); // Update the projects state with the fetched data
    }
  }, [projectsData]);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const handlePannerClick = (panner) => {
    setSelectedPanner(panner);
    const apiUrl = `https://panner.weirddata.com/api/${selectedProject.name}/panners/${panner.id}`;
    console.log(apiUrl);
    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response.data);
      setFullPanner(response.data);
    })
    .catch((error) => {
      console.error('Error fetching full Panner record:', error);
    });
    
  };

  const handlePannerEdit = (panner) => {
    // This handler function will be called when the user clicks the Save button in the PannerDetails component
    // It will send a POST request to the API to update the Panner record
    // The endpoint looks like this : `https://panner.weirddata.com/api/${selectedProject.name}/panners`;
    // The body of the POST will be a JSON object like the example in ExamplePanner.json
    // The Authorization header should contain the token
    // The Content-Type header should be application/json
    // The response will be the updated Panner object

    const apiUrl = `https://panner.weirddata.com/api/${selectedProject.name}/panners`;
    console.log(apiUrl);
    axios.post(apiUrl, panner, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log(response.data);
      setFullPanner(response.data);
    }
    )
    .catch((error) => {
      console.error('Error fetching full Panner record:', error);
    });
  };

  return (
    <div>
      <Navbar />

      <Box display="flex" height="100vh">

        {/* Left-side column for functions */}
        <Box w="10%" bg="#666262" isplay="flex" flexDirection="column" align="start">
          <Image src={PannerImage} alt="Logo"  />
          <Text fontWeight="bold" p={4} color="white">Projects</Text>

          {isPending && <p color="white">Loading projects...</p>}
          {error && <p>Error: {error}</p>}
          {projects.length > 0 ? (

            <Box ml={2}>
              {projects.map((project) => (
                <VStack align="left" key={project.id}>
                  <Link
                    color="white"
                    onClick={() => handleProjectClick(project)}
                    style={{ cursor: 'pointer' }} ml={4}>
                    {project.name}
                  </Link>
                </VStack>
              ))}
            </Box>

          ) : (<Text color="white">No projects available.</Text> )}

        </Box>

        {/* Panners List Area */}
        <Box w="15%">
          <Box>
            {selectedProject ? (
            <PannerProjects project={selectedProject} token={token} onPannerClick={handlePannerClick} />
            ) : (
              <Text >Select a project to view 'Panners'.</Text>
            )}
          </Box>
          
        </Box>

        <Box display="flex" height="100vh">

          {/* Separator Bar*/}
          <Box w="2%" bg="#666262" display="flex" flexDirection="column" align="start"/>
      
          {/* Panner Details*/}
          <Box w="60%" p={4}>
            {fullPanner && <PannerDetails panner={fullPanner} onEdit={handlePannerEdit} />}
          </Box>

        </Box>







    </Box>


    </div>
  );
}

export default Panners;
