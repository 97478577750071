import { useParams } from "react-router-dom";
import { Box, Heading, Text, Spinner, Alert, AlertIcon, Flex, Editable, EditableInput, EditablePreview } from "@chakra-ui/react";
import useGet from "./useGet";
import Navbar from './Navbar';
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Scenario = () => {
  const { token } = useContext(AuthContext); // Step 3
  // Use the token in your HTTP requests
  const config = {
    headers: {
      'Authorization': 'Bearer ${token}' // Step 4: Pass the token as an Authorization header
    }
  };
  const { id } = useParams();
  const { data: scenario, error, isPending } = useGet('https://testpilot.weirddata.com/api/ACORD/scenarios/' + id, token);
  const shortenUuid = (uuid) => {
    return uuid.slice(0, 5) + '...' + uuid.slice(-5);
  }

  return (
    <Box>
      {isPending && <Spinner size="xl" />}
      {error && 
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      }
      {scenario && 
        <Box p={5}>
          <Heading mb={4}>
            <Editable defaultValue={scenario.name} key={scenario.uuid}>
              <EditablePreview />
              <EditableInput />
            </Editable>
          </Heading>
          <Text>{shortenUuid(scenario.uuid)}</Text>
          <Heading size="md" mb={2} textDecoration="underline">Packages</Heading>
          <Flex direction="row" wrap="wrap" justify="space-around">
            {scenario.messagePackages.map((pkg, index) => (
              <Box key={index} p={3} shadow="md" borderWidth="1px" borderRadius="lg" m={2} flexBasis={{base: "100%", sm:"45%", md:"30%"}}>
                <Heading size="small">
                  <Editable defaultValue={pkg.name} key={pkg.uuid}>
                    <EditablePreview />
                    <EditableInput />
                  </Editable>
                </Heading>
                <Text>{shortenUuid(pkg.masterMessage.uuid)}</Text>
              </Box>
            ))}
          </Flex>
        </Box>
      }
    </Box>
  );
}
 
export default Scenario;
