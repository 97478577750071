import { Box, Image } from "@chakra-ui/react";
import Navbar from "./Navbar";
import MyImage from "./images/GetWired2.png";

const Home = () => {
  return (
    <Box className="home" height="100vh" display="flex" flexDirection="column">
      <Navbar />
      <Box flex="1" position="relative">
        <Image src={MyImage} alt="My Image" width="100%" height="auto" objectFit="cover" position="absolute" top="0" left="0" />
      </Box>
    </Box>
  );
};

export default Home;

