import React from 'react';
import { VStack, Box, Flex, Text, Button, ButtonGroup, IconButton, Link, Grid, GridItem, Image } from "@chakra-ui/react";
import useGet from './useGet'; // Import your useFetch component

const PannerProjects = ({ project, token, onPannerClick  }) => {
  const { data: panners, isPending, error } = useGet(`https://panner.weirddata.com/api/${project.name}/panners`, token);

  const handlePannerClick = (panner) => {
    // Call the onPannerClick function with the selected Panner
    onPannerClick(panner);
  };

  return (
    <div>
      <Text fontWeight="bold" p={4}>
        Panners for Project : {project.name}
      </Text>
      {isPending ? (
        <Text>Loading 'Panners'...</Text>
      ) : error ? (
        <Text>Error: {error}</Text>
      ) : (
        <Box ml={4}>

          {panners.items.length > 0 ? (

            panners.items.map((panner) => (
                <VStack align="left" key={panner.id}>
                <Link
                  style={{ cursor: 'pointer' }}
                  ml={4}
                  onClick={() => handlePannerClick(panner)} >
                  {panner.name}
                </Link>
              </VStack>
            ))

          ) : (
            <Text>No Panners available for this project.</Text>
          )}
        </Box>
      )}
    </div>
  );
};

export default PannerProjects;
