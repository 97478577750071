import React from 'react';
import axios from 'axios';
import LinkedInLoginImage from "./images/linkedinlogin.png";

const LinkedInAuthButton = () => {
    const handleLinkedInLogin = () => {
        const clientId = "78yqygzgnqclia";
        const redirectUri = "https://weirddata.com/linkedincallback";
        const state = "123";
    
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=openid profile email`;
      };

  return (
    <div onClick={handleLinkedInLogin} style={{ cursor: 'pointer' }}>
      <img
        src={LinkedInLoginImage}
        alt="Log in with LinkedIn"
        width="160"
        height="40"
      />
    </div>
  );
};

export default LinkedInAuthButton;
