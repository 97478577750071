import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Navbar from './Navbar';
import { Box, HStack, Image, Heading, Text, Input, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import MyImage from "./images/GetWiredLogo2.png";


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('https://panner.weirddata.com/api/Authentication/token', {
        username: username,
        password: password
      }, config)
      .then(response => {
        if (response.status === 302 || response.status === 307) {
          // Handle the redirection here
          const redirectURL = response.headers.location;
          return axios.post(redirectURL, {
            username: username,
            password: password
          }, config);
        }
        return response;
      })
      .then(response => {
        const token = response.data;
        console.log("Token:", token);
        login(token, username); // Call login() function from AuthContext
        navigate('/'); // Replace '/home' with the desired route
      })
      .catch(error => {
        console.log("Error");
        console.log(error);
        // Set the error message
        setErrorMessage(error.message || 'Something went wrong. Please try again.');
      });
  };

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Navbar />
      <Flex flex="1" align="center" justify="center">
        <Box width="400px" borderWidth="1px" borderRadius="lg" p="4">
          <HStack spacing="4" align="center">
            <Image src={MyImage} alt="Logo" w="100px" h="100px" />
            <Heading textAlign="center" as="h1" size="lg">Login</Heading>
          </HStack>
          {errorMessage && <Text>{errorMessage}</Text>}
          <Box as="form" onSubmit={handleSubmit} mt={4}>
            <Input
              type="text"
              id="username"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              mb={4}
            />
            <Input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              mb={4}
            />
            <Button type="submit" colorScheme="blue">Login</Button>
            
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
