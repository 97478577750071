import { Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import Home from './Home';
import Codex from './Codex';
import Curator from './Curator';
import Panners from './Panners';
import Scenarios from './Scenarios';
import Scenario from './Scenario';
import Source from './Source';
import Login from './Login';
import NotFound from './NotFound';
import Footer from './Footer'; 
import LinkedInLogin from './LinkedInLogin';
import LinkedInRedirect from './LinkedInRedirect';
import AccessTokenHandler from './AccessTokenHandler';
import LinkedInCallback from './LinkedInCallback';

function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/codex" element={<Codex />} />
        <Route path="/curator" element={<Curator />}>
        <Route path=":id" element={<Source />} />  // nested route
        </Route>
        <Route path="/panners" element={<Panners />}>
    
        </Route>
        <Route path="/scenarios" element={<Scenarios />}>
          <Route path=":id" element={<Scenario />} />  // nested route
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/linkedin-login" component={LinkedInLogin} />
        <Route path="/linkedincallback" element={<LinkedInCallback />} />
        <Route path="/linkedin-redirect" element={<LinkedInRedirect />} />
        <Route path="/access-token-handler" element={<AccessTokenHandler />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </ChakraProvider>
  )
}

export default App;
