import { Link as RouterLink } from "react-router-dom";
import { Text, Button, Box, Link, Flex, Image, Spacer} from "@chakra-ui/react";
import MyImage from "./images/GetWiredLogo2.png";

import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import LinkedInLogin from './LinkedInLogin';

const Navbar = () => {
  const { token, username, logout } = useContext(AuthContext);

  const handleLogout = () => {
    // Call the logout function to log the user out
    logout();
  };

  return (
    <Flex bg="#666262" p={4} color="white" align="center">
      <Image src={MyImage} alt="Logo" boxSize="50px" mr={4} />
      <Box display="flex" alignItems="center">
        <Link color="white" as={RouterLink} to="/" mr={4}>
          Home
        </Link>
        <Link color="white" as={RouterLink} to="/codex" mr={4}>
        Codex
        </Link>
        <Link color="white" as={RouterLink} to="/curator" mr={4}>
          Curator
        </Link>
        <Link color="white" as={RouterLink} to="/panners" mr={4}>
          Panner
        </Link>
        <Link color="white" as={RouterLink} to="/scenarios" mr={4}>
          Scenarios
        </Link>
      </Box>
      <Spacer />
      <Box>
      {token ? (
          <Flex align="center" ml="auto">
            <Text color="white" mr={2}>Logged in as {username}</Text>
            <Button colorScheme="red" size="sm" onClick={logout}>Logout</Button>
          </Flex>
        ) : (
          <Box>
          <LinkedInLogin />
        </Box>
        )}
      </Box>

    </Flex>
  );
}

export default Navbar;
