import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';

const LinkedInRedirect = () => {
  const location = useLocation();
  const history = useNavigate();
  const [previousPath, setPreviousPath] = useState(null);
  const { setLinkedInAccessToken } = useAuth();

  useEffect(() => {
    // Store the previous location
    setPreviousPath(location.pathname);

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    console.log("Code:", code);

    if (code) {
      // Make a POST request to exchange the code for an access token
      axios
        .post('https://www.linkedin.com/oauth/v2/accessToken', null, {
          params: {
            grant_type: 'authorization_code',
            code: code,
            client_id: '78yqygzgnqclia',
            client_secret: 'wtegTowFAzRqbFl5',
            redirect_uri: 'https://weirddata.com/linkedin-redirect',
          },
        })
        .then((response) => {
          const accessToken = response.data.access_token;

          // Store the access token securely, for example, in a cookie or local storage
          // You can also send it to your server for further processing
          setLinkedInAccessToken(accessToken);

          // Redirect back to the previous page or another appropriate route
          if (previousPath) {
            history(previousPath);
          } else {
            history('/'); // If there's no previous path, go to the home page
          }
        })
        .catch((error) => {
          console.error('Error exchanging code for access token:', error);

          // Redirect to an error page or another appropriate route
          history.push('/linkedin-error');
        });
    }
  }, [location, history, previousPath]);

  return (
    <div>
      <p>Exchanging code for access token...</p>
    </div>
  );
};

export default LinkedInRedirect;
