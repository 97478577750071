// Import necessary libraries
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Define your access token handling component
const AccessTokenHandler = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the access token from the query parameter
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('accessToken');

    if (accessToken) {
      // You can now use the access token as needed for authentication or API calls
      console.log('Received access token:', accessToken);
    } else {
      // Handle the case where the access token is missing or invalid
      console.error('Access token not found in the URL.');
    }
  }, [location.search]);

  return (
    <div>
      {/* You can add loading or error handling UI here */}
    </div>
  );
};

export default AccessTokenHandler;
