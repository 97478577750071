import { useParams } from "react-router-dom";
import { Box, Heading, Text, Spinner, Alert, AlertIcon, Flex } from "@chakra-ui/react";
import useGet from "./useGet";
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import './CreateSource.css'; 

const Source = () => {
  const { token } = useContext(AuthContext);
  const config = {
    headers: {
      'Authorization': 'Bearer ${token}'
    }
  };
  const { id } = useParams();
  console.log('URL:', 'https://curator.weirddata.com/api/sources/' + id);
  console.log('Config:', config);
  const { data: source, error, isPending } = useGet('https://curator.weirddata.com/api/sources/' + id, config);
  const shortenUuid = (uuid) => {
    return uuid.slice(0, 5) + '...' + uuid.slice(-5);
  }

  return (
    <Box>
      <Flex>
        <Box width={["full", "full", "50%"]} p={5}>
          <Heading as="h1" size="xl" mb={4}>Source Details</Heading>
          {isPending && <Spinner />}
          {error && 
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          }
          {source && ( // Check if source is not null
            <>
              <Text>
                <strong>Id:</strong> {shortenUuid(source.id)}
              </Text>
              <Text>
                <strong>Type:</strong> {source.type}
              </Text>
              <Text>
                <strong>Heading:</strong> {source.heading}
              </Text>
              <Text>
                <strong>Value:</strong> {source.value}
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default Source;
