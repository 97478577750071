import React, { Fragment, useState, useContext } from 'react';
import { Button, Box, Heading, Spinner, Alert, AlertIcon, Text, Flex, VStack, Grid, GridItem } from "@chakra-ui/react";
import { Routes, Route, useParams } from "react-router-dom";
import useGet from "./useGet";
import Navbar from './Navbar';
import Source from './Source';

import { AuthContext } from './AuthContext';
import CreateSource from './CreateSource'; // Import CreateSource component
import { useNavigate } from 'react-router-dom';

// Define the SourceWrapper here, before Curator function
const SourceWrapper = (props) => {
  const { id } = useParams();
  return <Source key={id} {...props} />;
};

const Curator = () => {
  const [isCreateSourceVisible, setIsCreateSourceVisible] = useState(false);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleCreateSourceForm = () => {
    setIsCreateSourceVisible(!isCreateSourceVisible);
  };

  // Use the token in your HTTP requests
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }};

    // Pass the 'config' object to 'useGet' for authenticated requests
    const { data: sources, error, isPending } = useGet('https://curator.weirddata.com/api/sources', config);
    const shortenUuid = (uuid) => {
      return uuid.slice(0, 5) + '...' + uuid.slice(-5);
    }

    return (
      <Box>
        <Navbar />
        <Grid templateColumns={["1fr", "1fr", "30% 70%"]} gap={5} p={5}>
          <VStack align="start" spacing={4}>
            <Heading as="h1" size="xl" mb={4}>
              Sources
            </Heading>
            {isCreateSourceVisible ? (
              <CreateSource
                userToken={token}
                onSourceCreated={() => {
                  navigate("/curator");
                  setIsCreateSourceVisible(false);
                }}
              />
            ) : (
              <Button colorScheme="green" variant="outline" onClick={toggleCreateSourceForm}>
                Create New Source
              </Button>
            )}
            {isPending && <Spinner />}
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
           {sources && (
  <Grid
    templateColumns="repeat(4, 1fr)"
    gap={2}
    style={{
      width: '100%',
    }}
  >

{sources && (
  <Grid
    templateColumns="1fr 1fr 1fr 1fr"
    gap={0}
    style={{
      width: '100%',
    }}
  >
    <Fragment>
      <GridItem borderWidth="1px" p={2} textAlign="left">
        <Box>
          <Heading as="h2" size="xs">
            Id
          </Heading>
        </Box>
      </GridItem>

      <GridItem borderWidth="1px" p={2} textAlign="left">
        <Box>
          <Heading as="h2" size="xs" width="xl">
            Type
          </Heading>
        </Box>
      </GridItem>

      <GridItem borderWidth="1px" p={2} textAlign="left">
        <Box>
          <Heading as="h2" size="xs" width="2xl">
            Heading
          </Heading>
        </Box>
      </GridItem>

      <GridItem borderWidth="1px" p={2} textAlign="left">
        <Box>
          <Heading as="h2" size="xs" width="3xl">
            Value
          </Heading>
        </Box>
      </GridItem>
    </Fragment>

    {sources.map((item) => (
      <Fragment key={item.id}>
        <GridItem borderWidth="1px" p={2} textAlign="left">
          <Box size="xs">
            {shortenUuid(item.id)}
          </Box>
        </GridItem>

        <GridItem borderWidth="1px" p={2} textAlign="left">
          <Box size="xs">
            {item.type}
          </Box>
        </GridItem>

        <GridItem borderWidth="1px" p={2} textAlign="left">
          <Box size="xs">
            {item.heading}
          </Box>
        </GridItem>

        <GridItem borderWidth="1px" p={2} textAlign="left">
          <Box size="xs">
            {item.value}
          </Box>
        </GridItem>
      </Fragment>
    ))}
  </Grid>
)}


  </Grid>
)}
          </VStack>
          <Box p={5}>
            <Routes>
              <Route path=":id" element={<SourceWrapper />} />
            </Routes>
          </Box>
        </Grid>
      </Box>
    );




  }

export default Curator;
