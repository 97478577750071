import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Import useAuth hook
import { Button, Box } from "@chakra-ui/react";

function CreateSource({ onSourceCreated }) {
  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [heading, setHeading] = useState('');
  const [value, setValue] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false); // Add state for form visibility

  const { token } = useAuth(); // Access token from the context

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const newSource = {
        id, 
        type,
        heading,
        value
      };

      // Send a POST request to create a new source
      const response = await axios.post('https://curator.weirddata.com/api/sources', newSource, {
        headers: {
          'Authorization': `Bearer ${token}`, // Use the user's token from the context
          'Content-Type': 'application/json',
        },
      });

      // If the source is created successfully, you can handle the response
      // or trigger a refresh of the sources in Curator.js
      console.log('New source created:', response.data);

      // Reset form fields
      setId('');
      setType('');
      setHeading('');
      setValue('');
      // Hide the form after saving
      setIsFormVisible(false);
      onSourceCreated(); // Notify the parent component
    } catch (error) {
      console.error('Error creating a new source:', error);
    }
  };

  return (
    <Box>
      <Button colorScheme="green" variant="outline" onClick={() => setIsFormVisible(!isFormVisible)}>
        {isFormVisible ? 'Cancel' : 'Create New Source'}
      </Button>
      {isFormVisible && (
        <form onSubmit={handleFormSubmit} className="grid-form">
          <div>
            <label>Id</label>
            <input type="text" value={id} onChange={(e) => setId(e.target.value)} />
          </div>
          <div>
            <label>Type</label>
            <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
          </div>
          <div>
            <label>Heading</label>
            <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} />
          </div>
          <div>
            <label>Value</label>
            <textarea value={value} onChange={(e) => setValue(e.target.value)} />
          </div>
          <div>
            <button colorScheme="green" variant="solid" onClick={() => handleFormSubmit}>Save</button>
          </div>

            

          
        </form>
      )}
    </Box>
  );
}

export default CreateSource;
