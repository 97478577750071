import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useGet = (url, token) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();

    const headers = {
      Authorization: `Bearer ${token}`, // Set the bearer token in the headers
    };

    console.log("Headers:", headers);
    setTimeout(() => {
      axios.get(url, { headers })
        .then(res => {
          setIsPending(false);
          setData(res.data);
          setError(null);
          console.log(url);
          console.log(res);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else if (err.response && err.response.status === 401) {
            // Redirect to the login page when a 401 error is encountered
            navigate('/login');
          } else {
            // handle other errors
            setIsPending(false);
            setError(err.message);
          }
        });
    }, 1000);

    // cancel the request if the component is unmounted
    return () => source.cancel('Operation canceled by the user.');
  }, [url, token, navigate]); // Include the token and navigate in the dependency array

  return { data, isPending, error };
}

export default useGet;
