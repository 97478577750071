import React from 'react';
import { Box, HStack, Image, Text, Link } from "@chakra-ui/react";
import linkedinLogo from "./images/LI-In-Bug.png";



const Footer = () => {
    return (
      <Box
        as="footer"
        p="4"
        bg="#666262"
        color="white"
        textAlign="center"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="white">&copy; 2023 Weirddata Ltd. All rights reserved.</Text>
        <HStack spacing="2">
          <Text color="white">Connect with us on</Text>
          <Box
            bg="white"
            p="3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Link
              href="https://www.linkedin.com/company/weirddata"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={linkedinLogo} alt="LinkedIn Logo" boxSize="50px" />
            </Link>
          </Box>
        </HStack>
      </Box>
    );
  };

export default Footer;
