import { Box, Heading, Spinner, Alert, AlertIcon, Flex, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { Routes, Route, Link, Outlet, useParams } from "react-router-dom";
import useGet from "./useGet";
import Navbar from './Navbar';
import Scenario from './Scenario';
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

// Define the ScenarioWrapper here, before Scenarios function
const ScenarioWrapper = (props) => {
  const { id } = useParams();
  return <Scenario key={id} {...props} />;
};

const Scenarios = () => {
  const { token } = useContext(AuthContext); // Step 3
  // Use the token in your HTTP requests
  const config = {
    headers: {
      'Authorization': `Bearer ${token}` // Use a template string to include the token
    }
  };

// Pass the 'config' object to 'useGet' for authenticated requests
// https://testpilot.weirddata.com/api/ACORD/scenarios
// https://localhost:7267/testpilot.weirddata.com/api/ACORD/scenarios
const { data: scenarios, error, isPending } = useGet('https://testpilot.weirddata.com/api/ACORD/scenarios', token);




  return (
    <Box>
      <Navbar />
      <Flex>
        <VStack align="start" spacing={4} width={["full", "full", "30%"]} p={5}>
          <Heading as="h1" size="xl" mb={4}>Scenarios</Heading>
          {isPending && <Spinner />}
          {error && 
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          }
          {scenarios && 
            scenarios.items.map((item) => (
              <ChakraLink as={Link} to={`${item.uuid}`} key={item.uuid}>
                {item.name}
              </ChakraLink>
            ))
          }
        </VStack>
        <Box width={["full", "full", "70%"]} p={5}>
          <Routes>
            <Route path=":id" element={<ScenarioWrapper />} /> {/* Use ScenarioWrapper here */}
          </Routes>
        </Box>
      </Flex>
    </Box>
  );
}

export default Scenarios;
