import React from "react";
import { Link as ChakraLink, Center, Text, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Center h="100vh">
      <div className="not-found">
        <Text textAlign="center">
          <Heading as="h1" size="2xl" color="blue.500">
            That was weird...
          </Heading>
          <br />
          <Heading as="h3" size="xl">
            ...that page doesn't exist!
          </Heading>
          <br />
          <ChakraLink as={Link} to="/">
            Back to the homepage...
          </ChakraLink>
        </Text>
      </div>
    </Center>
  );
};

export default NotFound;
